.device-settings {
  width: 24px;
  position: relative;

  li {
    list-style: none;
  }

  &:hover .menu {
    transform: scale(1);
  }

  .menu {
    position: absolute;
    bottom: 10px;
    right: -2px;
    z-index: 99999;
    margin: 0;
    padding: 0.5rem 0;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    transform: scale(0);
    transition: scale 0.2s ease-in-out;

    li {
      display: flex;
      align-items: center;
      justify-self: self-start;
      height: 30px;
      width: 100px;
      padding: 0.7rem 0.5rem;
      cursor: pointer;
      font-size: 16px;
      transition: all 300ms ease-in-out;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
